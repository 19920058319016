import { FC, ReactElement } from "react";
import { Navigate } from "react-router";
import { useSelector } from "../../store/hooks";
import { isLoggedIn } from "../../functions/permissions";
import { ROUTES } from "../../routes";

type Props = {
  children: ReactElement;
};

export const PrivateRoute: FC<Props> = ({ children }) => {
  const user = useSelector((state) => state.user.user);
  return isLoggedIn(user) ? children : <Navigate to={ROUTES.login()} />;
};
