import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { objectReducerGenerator } from "../objectReducerGenerator";
import { RootState } from "..";

export type RegisterStateT = {
  serialNumbers: string[];
  invoice: FileList | undefined;
};

const initialState: RegisterStateT = {
  serialNumbers: [""],
  invoice: undefined,
};

export const registerSlice = createSlice({
  name: "register",
  initialState,
  reducers: {
    ...objectReducerGenerator<RegisterStateT>(),
    setSerialNumbers: (
      state: RegisterStateT,
      { payload }: PayloadAction<string[]>
    ) => {
      state.serialNumbers = payload;
    },
    appendSerialNumber: (
      state: RegisterStateT,
      { payload }: PayloadAction<string>
    ) => {
      state.serialNumbers.push(payload);
    },
    removeSerialNumber: (
      state: RegisterStateT,
      { payload }: PayloadAction<number>
    ) => {
      state.serialNumbers.splice(payload, 1);
    },
    clearSerialNumbers: (state: RegisterStateT) => {
      state.serialNumbers = [""];
    },
    changeSerialNumber: (
      state: RegisterStateT,
      { payload }: PayloadAction<{ value: string; idx: number }>
    ) => {
      state.serialNumbers[payload.idx] = payload.value;
    },
    setInvoice: (
      state: RegisterStateT,
      { payload }: PayloadAction<FileList>
    ) => {
      state.invoice = payload;
    },
  },
});

export const selectUserSlice = (state: RootState) => state.user;

export const {
  setSerialNumbers,
  appendSerialNumber,
  removeSerialNumber,
  clearSerialNumbers,
  changeSerialNumber,
  setInvoice,
} = registerSlice.actions;
