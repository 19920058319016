import { AxiosResponse } from "axios";
import { addSeconds, differenceInMilliseconds, isBefore } from "date-fns";
import { toast } from "react-toastify";
import { all, call, delay, put, select, takeLatest } from "redux-saga/effects";
import { queryClient } from "../..";
import { ROLE_IDS } from "../../constants";
import { CLIENT_SECRET } from "../../env";
import {
  getCompany,
  getTokens,
  setCompany,
  setTokens,
} from "../../functions/localStorage";
import {
  AuthMutation,
  CLIENT_ID,
  getUserData,
  GRANT_TYPES,
  logoutUser,
  rotateTokens,
  TOKEN_ROTATION_BUFFER,
} from "../../functions/OAuth";
import { ROUTES } from "../../routes";
import { history } from "../../Routing";
import { client } from "../../services/axios";
import { ApiErrorT } from "../../types/Common";
import { UserCurrentT } from "../../types/User";
import {
  AuthUserActionType,
  changeCompanyId,
  hideSplashScreen,
  rotateTokensAction,
  RotateTokensActionType,
  setAuthLoading,
  setCurrentUser,
} from "../slices/userSlice";
import { firebaseTokenRegistration } from "../../services/firebase";
import { createQueryString } from "../../functions/routing";

function* rotateTokensSaga({ payload }: RotateTokensActionType) {
  yield delay(payload.timeToExpiration);
  try {
    const tokensData: AuthMutation = yield call(rotateTokens);
    yield put(
      rotateTokensAction({
        timeToExpiration: tokensData.expires_in * 1000,
      })
    );
  } catch (e) {
    logoutUser();
    yield put(hideSplashScreen());
    const { message } = e as ApiErrorT;
    toast.error(message);
  }
}

function* authUserSaga({ payload }: AuthUserActionType) {
  yield put(setAuthLoading(true));
  const { username, password, destination } = payload;
  try {
    const formData = new FormData();
    formData.append("password", password);
    formData.append("username", username);
    formData.append("client_id", CLIENT_ID);
    formData.append("client_secret", CLIENT_SECRET);
    formData.append("grant_type", GRANT_TYPES.password);

    const { data }: AxiosResponse<AuthMutation> = yield call(
      client.post,
      "/api/oauth2/token",
      formData
    );

    const {
      expires_in: expiresIn,
      access_token: accessToken,
      refresh_token: refreshToken,
    } = data;
    const timeToExpiration = expiresIn - TOKEN_ROTATION_BUFFER; // seconds

    // Handle TOKENS:
    //    1.  Calculate date of access token expiration
    //    2.  Save token info to local storage (access token, refresh token, expiration date)
    //    4.  Init token rotation starting minute before expiration (TOKEN_ROTATION_BUFFER is 1 min)
    //    5.  Dispatch action fetching users data
    //    ------------------------------------------------------------------------------------------

    const accessTokenExpirationDate = addSeconds(new Date(), timeToExpiration);
    setTokens(accessToken, refreshToken, accessTokenExpirationDate);
    yield put(
      rotateTokensAction({
        timeToExpiration: timeToExpiration * 1000,
      })
    );
    const user: UserCurrentT = yield call(getUserData);
    queryClient.invalidateQueries();
    yield put(setCurrentUser(user));

    if (user.role.id === ROLE_IDS.developer) {
      setCompany(0);
      yield put(changeCompanyId({ id: 0 }));
      history.push(destination || ROUTES.dashboard());
    } else if (user.companies.length > 1) {
      history.push(
        ROUTES.userSelectCompany() +
          createQueryString({ redirect: destination })
      );
    } else {
      setCompany(user.companies[0].id);
      yield put(changeCompanyId({ id: user.companies[0].id }));
      history.push(destination || ROUTES.dashboard());
    }
  } catch (e) {
    const { message } = e as ApiErrorT;
    toast.error(message);
  } finally {
    yield put(setAuthLoading(false));
  }
}

function* startupSequenceSaga() {
  try {
    const tokensData = getTokens();
    const selectedCompany = getCompany();
    if (!tokensData) {
      yield put(hideSplashScreen());
      return;
    }

    const currentDate = new Date();
    const tokenExpirationDate = new Date(tokensData.tokenExpirationDate);
    const isAccessTokenExpired = isBefore(tokenExpirationDate, currentDate);
    const timeToExpiration = differenceInMilliseconds(
      tokenExpirationDate,
      currentDate
    );

    if (!isAccessTokenExpired) {
      yield put(rotateTokensAction({ timeToExpiration }));
      const user: UserCurrentT = yield call(getUserData);
      yield put(setCurrentUser(user));
    } else {
      yield put(rotateTokensAction({ timeToExpiration: 0 }));
      yield delay(1);
      const user: UserCurrentT = yield call(getUserData);
      yield put(setCurrentUser(user));
    }

    yield put(hideSplashScreen());

    yield call(firebaseTokenRegistration);

    const user: UserCurrentT = yield select((store) => store.user.user);
    if (user.companies.length > 1 && selectedCompany === null) {
      history.push(ROUTES.userSelectCompany());
    }
    // else if (!history.location.pathname.includes("approve")) {
    //   history.go(-1);
    // }
  } catch (e) {
    const { message } = e as ApiErrorT;
    logoutUser();
    toast.error(message);
  }
}

export function* rootAuthSaga() {
  yield all([
    takeLatest("users/authUser", authUserSaga),
    takeLatest("users/rotateTokens", rotateTokensSaga),
    takeLatest("users/startupSequence", startupSequenceSaga),
  ]);
}
