import {
  createAction,
  createSelector,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";

import { objectReducerGenerator } from "../objectReducerGenerator";
import { storeClear } from "../actions";
import { UserCurrentT } from "../../types/User";
import { PermissionsT } from "../../types/Common";
import { getUserPermissions } from "../../functions/permissions";
import { RootState } from "..";
import { removeCompany } from "../../functions/localStorage";

export type UserStateT = {
  user: UserCurrentT | null;
  selectedCompanyId: number | null;
  permissions: PermissionsT;
  isSplashScreenShowing: boolean;
  isAuthLoading: boolean;
};

const initialState: UserStateT = {
  user: null,
  selectedCompanyId: null,
  permissions: {},
  isSplashScreenShowing: true,
  isAuthLoading: false,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    ...objectReducerGenerator<UserStateT>(),
    setCurrentUser: (
      state: UserStateT,
      { payload }: PayloadAction<UserCurrentT>
    ) => {
      return {
        ...state,
        user: payload,
        permissions: getUserPermissions(payload.role.id),
      };
    },
    setAuthLoading: (
      state: UserStateT,
      { payload }: PayloadAction<boolean>
    ) => {
      return {
        ...state,
        isAuthLoading: payload,
      };
    },
    hideSplashScreen: (state: UserStateT) => {
      return { ...state, isSplashScreenShowing: false };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(storeClear, () => {
      removeCompany();
      return { ...initialState, isSplashScreenShowing: false };
    });
  },
});

export const selectUserSlice = (state: RootState) => state.user;

export const { setCurrentUser, hideSplashScreen, setAuthLoading } =
  userSlice.actions;

export const selectUserPermissions = createSelector(
  selectUserSlice,
  (userState) => userState.permissions
);

export const authUserAction = createAction<{
  password: string;
  username: string;
  destination?: string;
}>("users/authUser");
export type AuthUserActionType = ReturnType<typeof authUserAction>;

export const rotateTokensAction =
  createAction<{ timeToExpiration: number }>("users/rotateTokens");
export type RotateTokensActionType = ReturnType<typeof rotateTokensAction>;

export const startupSequenceAction = createAction("users/startupSequence");

export const changeCompanyId =
  createAction<{ id: number }>("COMPANY_ID_CHANGED");
export type ChangeCompanyIdActionType = ReturnType<typeof changeCompanyId>;
