import { IconsId } from "./assets/fonts/iconfont/icons";
import { PermissionNamesT } from "./types/Common";
import { ShowActivityTypesT } from "./types/Route";
import { RoleT } from "./types/User";

export const ITEMS_PER_PAGE = 20;

export const LG_SCREEN_WIDTH = 1054;
export const XL_SCREEN_WIDTH = 1200;
export const XXL_SCREEN_WIDTH = 1400;

export const PRAGUE_LAT_LNG = {
  lat: 50.087957,
  lng: 14.423561,
};

export const DEVICE_STATUSES = {
  Status: "online",
  Status1: "offline",
};

export const DEVICE_ICONS: { [key: string]: IconsId } = {
  mower: "mower",
  ufon: "broadcast",
};

export const JOB_TYPE_IDS = {
  manual: 1,
  route: 2,
  perimeter: 3,
  auto: 4,
  survey: 5,
} as const;

export const JOB_STATUSES = {
  Open: "Open",
  Online: "Online",
  Offline: "Offline",
  Overdue: "Overdue",
  Finished: "Finished",
};

export const USER_ROLES: RoleT[] = [
  { id: 1, name: "Developer" },
  { id: 2, name: "Manager" },
  { id: 3, name: "Mower operator" },
];

export const TASK_TYPES = {
  setupUfon: "setupUfon",
  startMowing: "startMowing",
  moweMowerToStartPoint: "moweMowerToStartPoint",
  manualModeToSetPerimeter: "manualModeToSetPerimeter",
  setNoGoZones: "setNoGoZones",
  calculateRoute: "calculateRoute",
  collectMower: "collectMower",
  collectUfon: "collectUfon",
};

export const TASK_STATUSES = {
  start: "start",
  pause: "pause",
  resume: "resume",
  done: "done",
};

export const DATE_FORMATS = {
  date: "d.M.Y",
  dateTime: "d.M.Y H:mm:ss",
  geostickDateTime: "d.M.y HH:mm:ss",
  dateBackend: "Y-MM-dd",
  dateForm: "yyyy-MM-dd",
  dateTimeShort: "d.M H:mm",
  dateTimeMedium: "d.M.yyyy H:mm",
};

export const VALIDATIONS = {
  required: "This field is required",
  email: "This is not a valid email address",
  phone: 'This is not a valid phone number, must be "+XXXXXXXXXXXX"',
  nan: "This value must be a number",
  vat: "This is not a valid VAT identification number (DIČ)",
};

export const ROLE_IDS = {
  developer: 1,
  manager: 2,
  chiselMain: 3,
  chisel: 4,
};

export const PERMISSIONS: Record<
  PermissionNamesT,
  Array<keyof typeof ROLE_IDS>
> = {
  dashboardJobs: ["manager", "chiselMain", "chisel"],
  jobManage: ["developer", "manager"],
  jobUsersAll: ["developer", "manager", "chiselMain"],
  routeManage: ["developer", "manager"],
  deviceManage: ["developer"],
  deviceCreate: ["developer"],
  usersVisibility: ["developer", "manager"],
  companiesVisibility: ["developer"],
  createDeveloper: ["developer"],
};

export const UTM_ZONES = {
  31: 31,
  32: 32,
  33: 33,
};

export const WINDOW_TITLE_NAMES = {
  dashboard: "Dashboard | Autonomous mowers",
  jobs: "Jobs | Autonomous mowers",
  routes: "Routes | Autonomous mowers",
  devices: "Devices | Autonomous mowers",
  users: "Users | Autonomous mowers",
  companies: "Companies | Autonomous mowers",
  statistics: "Statistics | Autonomous mowers",
  notifications: "Notifications | Autonomous mowers",
  default: "Autonomous mowers",
};

export const JOB_ROUTE_TYPE_COLORS = {
  plannedRoute: "#DA00C4",
  activeDevice: "#D9D621",
  inactiveDeviceFill: "#DCDCDC",
  inactiveDeviceStroke: "#7E7E7E",
  calculatedRoute: "#FB2D30",
};

export const DEVICE_DETAIL_COLORS = {
  default: "#999999",
  black: "#353535",
  ok: "#BDBA31",
  warning: "#FFCA05",
  error: "#FF1214",
  info: "#1577B3",
};

// dashed line
export const lineSymbol = {
  path: "M 0,-1 0,1",
  strokeOpacity: 1,
  scale: 2.5,
};

// dash followed by two dots: -..
export const manualMowingSymbol = {
  path: "M 0,-1 0,3 m 0,2 0,1 m 0,2 0,1",
  strokeOpacity: 1,
  scale: 2.5,
};

export const INTERNET_VALUE = {
  max: 100,
  strong: 80,
  warning: 40,
  fair: 20,
  error: 10,
};

export const SATELLITE_VALUE = {
  warning: 11,
  error: 2,
};

export const BATTERY_VALUE = {
  strong: 90,
  good: 60,
  warning: 30,
  error: 10,
};

const TEN_MINUTES = 60 * 10;
export const CONNECTION_CUTOFF = {
  ufon: TEN_MINUTES,
  mower: TEN_MINUTES,
};

export const LOCALE = "en-US";
export const LOCALE_START_OF_WEEK = 1;

export const DEFAULT_SHOW_ACTIVITY_TYPES: ShowActivityTypesT = {
  autonomousMowing: true,
  autonomousTransit: true,
  manualMowing: true,
  manualTransit: true,
};

export const STATISTICS_COLORS = {
  autonomousMowing: "#CCC918",
  autonomousTransit: "#E5E39C",
  manualMowing: "#1FA9FF",
  manualTransit: "#9CD9FE",
  plannedMowing: "#DA00C4",
  plannedTransit: "#FBE0F8",
};
