import React, { FC, ReactNode } from "react";
import classNames from "classnames";
import logo from "../../../assets/images/logo.svg";
import bgLogin from "../../../assets/images/bg-login.jpg";
import { StyledButton } from "../../atoms/Button/Button";
import styles from "./styles.module.scss";

type PropsT = {
  children: ReactNode;
  wide?: boolean;
};

export const LoginScreenLayout: FC<PropsT> = ({ children, wide }) => {
  return (
    <div className={styles.layout}>
      <div className="container-fluid">
        <div className="grid">
          <div className="g-col-12 g-col-lg-5">
            <div
              className={classNames(
                styles.wrapper,
                wide && styles["wrapper-wide"]
              )}
            >
              <div className={styles.form}>
                <img
                  className={classNames("d-block mx-auto", styles.logo)}
                  src={logo}
                  alt="Spider Autonomous logo"
                  width="227"
                  height="49"
                />
                {children}
              </div>
              <StyledButton
                tag="a"
                href="mailto:info@spidernewtech.com"
                block
                color="link"
                size="sm"
                title="Contact support"
              />
            </div>
          </div>
          <div className="g-col-12 g-col-lg-7 d-none d-lg-block">
            <div className={styles.background}>
              <img
                src={bgLogin}
                alt="A Spider Autonomous mower in a grass field"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
